import React,{useState,useEffect} from 'react';
import {Card, CardBody, Table} from "reactstrap";
import Loader from '../../../components/Loader';
import API from '../../../utils/API';
import SummaryChart from '../../dashboards/Lender/SummaryChart';
import Dateday from '../../../components/fields/Dateday';
import MoneyTotal from '../../../components/fields/MoneyTotal';
import OrderStatus from '../../../components/status/OrderStatus';
import Product from '../../../components/fields/Product';


const OrderReportDetailByDate = ({filtration}) => {
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)

    let totalLeads = 0, totalMatched = 0, totalReturned = 0, totalDenied = 0, totalRequested = 0, totalTotal = 0, totalNet = 0

    useEffect(() => {
        if (filtration.date) {
            setLoading(true)
            API.post('/lender/ordersdetails', filtration).then((response) => {
                setData(response.data.data.report)
                setLoading(false)
            })
        }

    }, [filtration])

    return <>
            {loading &&
            <Loader/>}
            {(!loading && data && data.length > 0) ?
                <>
                    <Card className="flex-fill w-100">
                        <div className="table-responsive">
                        <Table striped className="my-0">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Order</th>
                                <th>Status</th>
                                <th>Product</th>
                                <th>Total Sent</th>
                                <th>Return Requested</th>
                                <th>Total Denied</th>
                                <th>Total Credited</th>
                                <th>Net</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.map((dataItem,dataIndex)=>{
                                    totalLeads += dataItem.leads
                                    totalMatched += dataItem.matched
                                    totalReturned += dataItem.returned
                                    totalDenied += dataItem.denied
                                    totalRequested += dataItem.requested
                                    totalTotal += dataItem.total
                                    totalNet += dataItem.net
                                    return (
                                        <tr key={dataIndex}>
                                            <td><Dateday dateDay={dataItem.date}/></td>
                                            <td>{'#' + dataItem.order.id + ' ' + dataItem.order.title}</td>
                                            <td><OrderStatus status={dataItem.order.status}/></td>
                                            <td><Product product={dataItem.order.product}/></td>
                                            <td className="font-weight-bold">{dataItem.leads}</td>
                                            <td className={`${dataItem.requested?'text-warning':''}`}>{dataItem.requested}</td>
                                            <td className={`${dataItem.denied?'text-danger':''}`}>{dataItem.denied}</td>
                                            <td className={`${dataItem.returned?'text-primary':''}`}>{dataItem.returned}</td>
                                            <td className={`font-weight-bold ${dataItem.net?'text-success':''}`}>{dataItem.net}</td>
                                            <td>{dataItem.order.ispingpost == '1' ? 'Ping/Post' : '$' + dataItem.order.price}</td>
                                            <td>{dataItem.total?<MoneyTotal money={dataItem.total}/>:'$0'}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td className="font-weight-bold">Total</td>
                                <td colSpan={3}/>
                                <td className="font-weight-bold">{totalLeads}</td>
                                <td className={`font-weight-bold ${totalRequested?'text-warning':''}`}>{totalRequested}</td>
                                <td className={`font-weight-bold ${totalDenied?'text-danger':''}`}>{totalDenied}</td>
                                <td className={`font-weight-bold ${totalReturned?'text-primary':''}`}>{totalReturned}</td>
                                <td className={`font-weight-bold ${totalReturned?'text-success':''}`}>{totalNet}</td>
                                <td colSpan={1}/>
                                <td className={`font-weight-bold`}>{totalTotal?<MoneyTotal money={totalTotal}/>:'$0'}</td>
                            </tr>
                            </tbody>
                        </Table>
                        </div>
                    </Card>
                </>
                : !loading &&
                <Card><CardBody className="text-center"><h5>Data not found</h5></CardBody></Card>
            }
    </>
}

export default OrderReportDetailByDate