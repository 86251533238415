import React, {useState} from "react";
import {Col, Container, Row, NavItem, NavLink, Nav, TabContent, TabPane} from "reactstrap";
import dateFormat from "dateformat";
import DateRange from "../../components/formfields/DateRange";
import OrderStatusSelect from "../../components/formfields/OrderStatusSelect";
import OrderSelect from "../../components/formfields/OrderSelect";
import classnames from "classnames";
import OrderReportSummary from "./displaytype/OrderReportSummary";
import OrderReportDetailByDate from "./displaytype/OrderReportDetailByDate";

const OrdersDetails = () => {
    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 6),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")

    const [filtration,setFiltration] = useState({date:defaultDate})
    const [activeTab,setActiveTab] = useState("summary")

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    const changeNativeData = (e) => {
        setFiltration({ ...filtration, [e.target.name]: e.target.value })
    }

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Orders Details</h1>
            <Row form className={"mb-2"}>
                <Col md={3} hidden={activeTab === 'summary' ? true : false}>
                    <DateRange
                        onChange={changeData}
                        name={'date'}
                        value={filtration.date??defaultDate}
                    />
                </Col>
                <Col md={3}>
                    <OrderStatusSelect
                        name={"status"}
                        onChange={changeData}
                    />
                </Col>
                <Col md={3} hidden={activeTab === 'summary' ? true : false}>
                    <OrderSelect
                        name={"lenderorder"}
                        onChange={changeData}
                        lender={filtration?.lender}
                    />
                </Col>
            </Row>
            <div className={"tab default mt-3"}>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "summary" })}
                            onClick={() => {
                                setActiveTab("summary");
                            }}
                        >
                            <strong>Summary</strong>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "bydate" })}
                            onClick={() => {
                                setActiveTab("bydate");
                            }}
                        >
                            <strong>Detail by date</strong>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="summary" style={{minHeight:"100%"}}>
                        <OrderReportSummary filtration={filtration}/>
                    </TabPane>
                    <TabPane tabId="bydate" style={{minHeight:"100%"}}>
                        <OrderReportDetailByDate filtration={filtration}/>
                    </TabPane>
                </TabContent>
            </div>
        </Container>
    )
}

export default OrdersDetails
