import React,{useState,useEffect} from "react";
import { Container,Card,Table,Row,Col } from "reactstrap";
import Header from "./Header";
import DateRange from "../../../components/formfields/DateRange";
import dateFormat from "dateformat";
import API from "../../../utils/API";
import SummaryChart from "./SummaryChart";
import Dateday from "../../../components/fields/Dateday";
import MoneyTotal from "../../../components/fields/MoneyTotal";
import OrderSelect from "../../../components/formfields/OrderSelect";

const Ecommerce = () => {

    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 6),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")

    const [filtration,setFiltration] = useState({date:defaultDate})
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)

    let totalLeads = 0, totalTotal = 0

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(() => {
        if (filtration.date) {
            setLoading(true)
            API.post('/lender/dashboard', filtration).then((response) => {
                setData(response.data.data)
                setLoading(false)
            })
        }
    }, [filtration])

    return(
    <Container fluid className="p-0">
        <Header/>
        <Row>
            <Col md={3}>
                <DateRange
                    onChange={changeData}
                    name={'date'}
                    value={defaultDate}
                />
            </Col>
            <Col md={3}>
                <OrderSelect
                    name={"orderId"}
                    onChange={changeData}
                    lender={filtration?.lender}
                />
            </Col>
        </Row>
        {data &&
            <Card className="flex-fill w-100 mt-3">
            <div className="row mt-3">
                {data.length > 2 &&
                    <div className="col-md-8 col-sm-12">
                        <SummaryChart inputData={data}/>
                    </div>
                }
                <div className="col-md-4 col-sm-12" style={{overflowY:"auto",maxHeight:"430px"}}>
                        <div className="table-responsive">
                            <Table striped borderless className="my-0">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Matched</th>
                                    <th>Cost</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    data.map((dataItem,dataIndex)=>{
                                        totalLeads += dataItem.leads
                                        totalTotal += dataItem.total
                                        return (
                                            <tr key={dataIndex}>
                                                <td><Dateday dateDay={dataItem.date}/></td>
                                                <td className="font-weight-bold">{dataItem.leads}</td>
                                                <td>{dataItem.total?<MoneyTotal money={(dataItem.total)}/>:'$0'}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td/>
                                    <td className="font-weight-bold">{totalLeads}</td>
                                    <td className={`font-weight-bold`}>{totalTotal?<MoneyTotal money={totalTotal}/>:'$0'}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                </div>
            </div>
            </Card>
        }
    </Container>
    )
};

export default Ecommerce;
