import React,{useState,useEffect} from "react";
import {
    Card,
    Col,
    Container, Row, Table, CardBody
} from "reactstrap";
import dateFormat from "dateformat";
import DateRange from "../../components/formfields/DateRange";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import ListStat from "../../components/ListStat";
import API from "../../utils/API";
import MoneyTotal from "../../components/fields/MoneyTotal";
import ReturnStatus from "../../components/fields/ReturnStatus";

const Returns = () => {
    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 6),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")
    const [filtration,setFiltration] = useState({page:1,perPage:100,date:defaultDate})
    const [returns, setReturns] = useState(null)
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    useEffect(() => {
        setLoading(true)
        API.post('/lender/return/log',filtration).then((response) => {
            setReturns(response.data.data.data)
            setData(response.data.data)
            setLoading(false)
        });
    }, [filtration])

    return <Container fluid className="p-0">
        <h1 className="h3 mb-3">Returns</h1>
        <Row form>
            <Col md={4}>
                <DateRange
                    onChange={changeData}
                    name={'date'}
                    value={defaultDate}
                />
            </Col>
        </Row>
        <Card className="mt-2">
            {loading &&
                <Loader absolute={true}/>
            }
            {returns?.length > 0 ?
                <div className="table-responsive">
                    <ListStat data={data}/>
                    <Table>
                        <thead>
                        <tr>
                            <th>Lead Id</th>
                            <th>Lead Date</th>
                            <th>Lead Phone</th>
                            <th>Lead Email</th>
                            <th>Lead Cost</th>
                            <th>Return request Date</th>
                            <th>Return Status</th>
                            <th>Return Status Updated Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            returns.map((returnItem, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{returnItem.lead}</td>
                                        <td>{returnItem.leaddate}</td>
                                        <td>{returnItem.phone}</td>
                                        <td>{returnItem.email}</td>
                                        <td><MoneyTotal money={returnItem.cost}/></td>
                                        <td>{returnItem.requestdate}</td>
                                        <td><ReturnStatus status={returnItem.returnstatus}/></td>
                                        <td>{returnItem.finilizeddate}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
                :
                !loading &&
                <CardBody className="text-center"><h5>Data not found</h5></CardBody>
            }
        </Card>
        {(data?.page > 0 && data?.pages > 0) &&
            <Pagination
                onChange={changeData}
                name={"page"}
                page={data.page}
                totalPages={data.pages}/>
        }
    </Container>
}

export default Returns
