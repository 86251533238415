import React,{useEffect,useState} from "react";
import API from "../utils/API";

const AccountSelect = () => {
    const [accounts,setAccounts] = useState(null);

    const currentAccount =  localStorage.getItem('accountid');

    useEffect(() => {
        API.get('/user/lender/accounts').then((result)=>{
            let data = result.data.data;
            setAccounts(data);

            if (!localStorage.getItem('accountid') && data.length > 1) {
                localStorage.setItem('accountid',data[0].id);
            }
        });
    }, []);

    const changeAccount = (accountId) => {
        localStorage.setItem('accountid',accountId);
        window.location.reload();
    }

    return <>{accounts &&
        <>
            {accounts.length > 1 ?
                <select className="mt-1 mr-2 form-control" onChange={(e)=>changeAccount(e.target.value)} value={localStorage.getItem('accountid')}>
                    {accounts.map((account,index)=>{
                        return <option key={index} value={account.id}>{account.title}</option>
                    })}
                </select>
                :
                <h6 style={{marginTop:"11px",marginRight:"10px"}}>{accounts[0].title}</h6>
            }
        </>
    }</>
}

export default AccountSelect;
