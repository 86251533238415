import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import Select from "react-select";

const OrderSelect = ({name,value,onChange,lender}) => {
    const [order,setOrder] = useState(null)

    const changeSelect = (value) => {
        let i,values = []
        for (i=0;i<value?.length;i++) {
            values.push(value[i].value)
        }
        if (name) {
            onChange(name,values)
        }
    }

    useEffect(() => {
        let data = {}
        if (lender) {
            data.lender = lender
        }
        API.post('/lender/system/orders',data).then((response) => {
            let options,i,orderList = []
            if (response.data.data) {
                options = response.data.data
                for (i=0;i<options.length;i++) {
                    orderList.push({value:options[i].id,label:'#' + options[i].id + ' ' +options[i].title})
                }
            }
            setOrder(orderList)
        });
    }, [lender])

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={order}
            value={value}
            onChange={(value)=>changeSelect(value)}
            isMulti
            placeholder="Order"
        />
    )
}

export default OrderSelect;
