import React,{useState,useEffect} from "react";
import CreatableSelect from 'react-select/creatable';

const ReturnReason = ({name,value,onChange,reasons}) => {
    const [defaultValue,setDefaultValue] = useState(null)
    const [options,setOptions] = useState(null)

    useEffect(()=>{
        const optionsTemp = []

        let valueIncluded = false;

        reasons.forEach(element => {
            optionsTemp.push({value:element,label:element})

            if (value && value.toLowerCase() === element.toLowerCase()) {
                valueIncluded = true;
            }
        })

        if (value) {
            optionsTemp.push({value:(!valueIncluded?'*****':'')+value,label:value})
            setDefaultValue({value:(!valueIncluded?'*****':'')+value,label:value})
            onChange(name,(!valueIncluded?'*****':'')+value)
        }

        setOptions(optionsTemp)
    },[value,reasons])

    const changeSelect = (value) => {
        setDefaultValue(value)
        onChange(name,value?.value)
    }

    return (
        <>
            {options &&
            <CreatableSelect
                isClearable
                createOptionPosition="first"
                formatCreateLabel={(string)=>{return string}}
                className="react-select-container text-left"
                classNamePrefix="react-select"
                options={options}
                value={defaultValue}
                onChange={(value)=>changeSelect(value)}
                placeholder="Select reason"
                maxMenuHeight={90}
                onCreateOption={(inputValue)=> {
                    setDefaultValue({value:`*****${inputValue}`,label:inputValue})
                    onChange(name,`*****${inputValue}`)
                }}
            />
            }
        </>
    )
}

export default ReturnReason;
