import React, {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import API from "../../utils/API";
import {login} from "../../redux/actions/userActions";

const OneClickLogIn = (props) => {

    const dispatch = useDispatch();

    const [loading,setLoading] = useState(true)

    const sign = useSelector(state => {
        return state.user.sign
    });

    useEffect(()=>{
        API.get('/user/lenderoneclicklogin/'+props.match.params.token).then((response) => {
            dispatch(login(response.data.data));
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        })
    },[])


    return <>{(!loading && (sign)) && <Redirect to="/"/>}</>
}

export default OneClickLogIn;
