import async from "../components/Async";

import {
  Sliders as SlidersIcon,
  Users as UsersIcon,
  CornerDownLeft as ReturnIcon,
  PieChart as PieChartIcon
} from "react-feather";

// Auth
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
import Returns from "../pages/returns/Returns";
import SubmitReturns from "../pages/returns/SubmitReturns";
import Ecommerce from "../pages/dashboards/Lender";
import Profile from "../pages/account/Profile";
import ChangePassword from "../pages/account/ChangePassword";
import LeadsDetails from "../pages/report/LeadsDetails";
import MultiFactorAuth from "../pages/account/MultiFactorAuth";
import OrdersDetails from "../pages/report/OrdersDetails";

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  icon: SlidersIcon,
  component: Ecommerce,
  header: "Partner portal",
  privateRouter: true,
  children: null
};

const reportRoutes = {
  name: "Reports",
  path: "/reports",
  icon: PieChartIcon,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/reports/details",
      name: "Leads details",
      privateRouter: true,
      component: LeadsDetails
    },
    {
      path: "/reports/orderdetails",
      name: "Orders details",
      privateRouter: true,
      component: OrdersDetails
    }
  ]
};

const returnRoutes = {
  name: "Returns",
  path: "/return",
  icon: ReturnIcon,
  privateRouter: true,
  open:false,
  children: [
    {
      path: "/return/list",
      name: "List",
      privateRouter: true,
      component: Returns
    },
    {
      path: "/return/new",
      name: "Submit",
      privateRouter: true,
      component: SubmitReturns
    },
    {
      path: "/profile",
      name: "Profile settings",
      privateRouter: true,
      component: Profile,
      hideInMenu: true
    },
    {
      path: "/profile/password",
      name: "Change password",
      privateRouter: true,
      component: ChangePassword,
      hideInMenu: true
    },
    {
      path: "/profile/mfa",
      name: "Multi-factor authentication",
      privateRouter: true,
      component: MultiFactorAuth,
      hideInMenu: true
    }
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  privateRouter: false,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      privateRouter: false,
      component: SignIn
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      privateRouter: false,
      component: ResetPassword
    }
  ]
};

// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  reportRoutes,
  returnRoutes,
];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
  dashboardRoutes,
  reportRoutes,
  returnRoutes,
];
