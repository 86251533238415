import React,{useState,useEffect} from "react";
import {
    Card,
    Col,
    Container, Row, Table, CardBody, Spinner
} from "reactstrap";
import dateFormat from "dateformat";
import DateRange from "../../components/formfields/DateRange";
import Pagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import ListStat from "../../components/ListStat";
import API from "../../utils/API";
import MoneyTotal from "../../components/fields/MoneyTotal";
import Product from "../../components/fields/Product";
import CsvDownloader from "react-csv-downloader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons";

const LeadsDetails = () => {
    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 31),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")
    const [filtration,setFiltration] = useState({page:1,perPage:100,date:defaultDate})
    const [leads, setLeads] = useState(null)
    const [loading, setLoading] = useState(true)
    const [data,setData] = useState(null)

    const [exportLoading,setExportLoading] = useState(false)
    const [logExport,setLogExport] = useState(false)

    const changeData = (name,value) => {
        setFiltration({ ...filtration, [name]: value })
    }

    const handleChange = (e) => {
        setFiltration({ ...filtration, [e.target.name]: e.target.value })
    };

    const getleads = () => {
        setLoading(true)
        setExportLoading(true)
        setLogExport(null)

        API.post('/lender/return/leadsdetails',filtration).then((response) => {
            setLeads(response.data.data.data)
            setData(response.data.data)
            setLoading(false)
        });

        API.post('/lender/return/leadsexport',filtration).then((response) => {
            setLogExport(response.data.data)
            setExportLoading(false)
        });
    }

    useEffect(() => {
        getleads()
    }, [filtration])

    return (<Container fluid className="p-0">
        <div className="row justify-content-between">
            <div className="col-auto">
                <h3 className="h3 mb-3 float-left">Leads Details</h3>
            </div>
            <div className="col-auto">
                {exportLoading &&
                    <Spinner color="primary" size={"sm"} className="mr-3 mt-1" children={''}></Spinner>
                }
                {logExport &&
                    logExport.map((item,index) => {
                        return (
                            <CsvDownloader key={index} datas={item.rows} filename={`${item.title} leads`}>
                                <FontAwesomeIcon
                                    className="align-middle cursor-pointer"
                                    icon={faFileCsv}
                                    style={{fontSize:"20px"}}
                                    fixedWidth
                                /> Download <strong>{item.title}</strong> leads
                            </CsvDownloader>
                        )
                    })
                }
            </div>
        </div>
        <div className="row">
            <div className="col-md-4 col-sm-12">
                <label>Date range</label>
                <DateRange
                    onChange={changeData}
                    name={'date'}
                    value={defaultDate}
                />
            </div>
            <div className="col-md-4 col-sm-12">
                <label>Email</label>
                <input type="text" className="form-control" name="email" onChange={handleChange}></input>
            </div>
            <div className="col-md-4 col-sm-12">
                <label>Phone</label>
                <input type="text" className="form-control" name="phone" onChange={handleChange}></input>
            </div>
        </div>
        <div className="row mt-2">
            <div className="col-md-12 col-sm-12">
                <label>Lead Id's (comma separated)</label>
                <textarea className="form-control" rows="4" name="id" onChange={handleChange}></textarea>
            </div>
        </div>
        <Card className="mt-4">
            {loading &&
                <Loader absolute={true}/>
            }
            {leads?.length > 0 ?
                <div className="table-responsive">
                    <ListStat data={data}/>
                    <Table>
                        <thead>
                        <tr>
                            <th>Lead Id</th>
                            <th>Date</th>
                            <th>Product</th>
                            <th>Order</th>
                            <th>Cost</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            leads.map((lead, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{lead.leadsearchdata.id}</td>
                                        <td>{lead.date}</td>
                                        <td><Product product={lead.product}/></td>
                                        <td>{lead.ordertitle}</td>
                                        <td><MoneyTotal money={lead.earntotal}/></td>
                                        <td>{lead.leadsearchdata.firstname} {lead.leadsearchdata.lastname}</td>
                                        <td>{lead.leadsearchdata.email}</td>
                                        <td>{lead.leadsearchdata.phone}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
                :
                !loading &&
                <CardBody className="text-center"><h5>Data not found</h5></CardBody>
            }
        </Card>
        {(data?.page > 0 && data?.pages > 0) &&
            <Pagination
                onChange={changeData}
                name={"page"}
                page={data.page}
                totalPages={data.pages}/>
        }
        </Container>)
}

export default LeadsDetails;
