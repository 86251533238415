import React,{useState,useEffect} from 'react';
import {Card, CardBody, Table} from "reactstrap";
import Loader from '../../../components/Loader';
import API from '../../../utils/API';
import OrderStatus from '../../../components/status/OrderStatus';
import Product from '../../../components/fields/Product';


const OrderReportSummary = ({filtration}) => {
    const [loading, setLoading] = useState(true)
    const [orders,setOrders] = useState(null)

    useEffect(() => {
        setLoading(true)
        API.post('/lender/capmanagement/orders', filtration).then((response) => {
            setOrders(response.data.data)
            setLoading(false)
        })
    }, [filtration.status]);

    return <>
            {loading &&
            <Loader/>}
            {(!loading && orders && orders.length > 0) ?
                <Card className="flex-fill w-100">
                    <div className="table-responsive">
                        <Table striped className="my-0">
                            <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Title</th>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Status</th>
                                <th>Leads</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map(data => {
                                return (
                                    <tr>
                                        <td>{data.lenderorder.id}</td>
                                        <td>{data.lenderorder.title}</td>
                                        <td><Product product={data.product}/></td>
                                        <td><strong>{(data.lenderorder?.ispingpost == "1" ? 'Ping/Post' : data.lenderorder.price !== null ? '$' + data.lenderorder.price : 'Undefined')}</strong></td>
                                        <td><OrderStatus status={data.lenderorder.status}/></td>
                                        <td>
                                            <nobr><span className="small">Total Sent: {(data.caplenderordercache.returnedoverall && parseInt(data.caplenderordercache.returnedoverall)) + (data.caplenderordercache.matchedoverall && parseInt(data.caplenderordercache.matchedoverall))}</span></nobr><br />
                                            <nobr><span className="small">Total Returned: {data?.caplenderordercache?.returnedoverall}</span></nobr><br />
                                            <nobr><span className="small">Total Net: {data?.caplenderordercache?.matchedoverall}</span></nobr><br />
                                        </td>
                                    </tr>
                                )
                                }
                            )}
                            </tbody>
                        </Table>
                    </div>
                </Card>
                : !loading &&
                <Card><CardBody className="text-center"><h5>Data not found</h5></CardBody></Card>
            }
    </>
}

export default OrderReportSummary