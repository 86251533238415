import React,{useState,useEffect} from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, Card, CardBody, Container, Nav, NavItem, NavLink, Spinner, TabContent, Table, TabPane
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory, faCheck, faTimes, faExclamation} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { FileUploader } from "react-drag-drop-files";
import DateRange from "../../components/formfields/DateRange";
import dateFormat from "dateformat";
import API from "../../utils/API";
import Datetime from "../../components/fields/Datetime";
import ReturnReason from "../../components/formfields/ReturnReason";
import csvExample from "../../assets/img/returnexample.png";
import {toastr} from "react-redux-toastr";

const SubmitReturns = (props) => {
    const [activeTab,setActiveTab] = useState("search")
    const defaultDate = dateFormat((new Date()).setDate((new Date()).getDate() - 14),"mmm d, yyyy")+" to "+dateFormat(new Date(),"mmm d, yyyy")
    const [searchData,setSearchData] = useState({date:defaultDate})
    const [file,setFile] = useState(null)
    const [leads,setLeads] = useState(null)
    const [loading,setLoading] = useState(false)
    const [leadType,setLeadType] = useState(null)

    const [disputeList,setDisputeList] = useState([])

    const [reasons,setReasons] = useState(null)

    const [returnInProcess,setReturnInProcess] = useState(false)
    const [returnResult,setReturnResult] = useState(null)

    useEffect(()=>{
        API.get('/lender/system/returnreason').then((response) => {
            setReasons(response.data.data)
        });
    },[])

    const changeData = (name,value) => {
        setSearchData({ ...searchData, [name]: value })
    }

    const handleChange = (e) => {
        setSearchData({ ...searchData, [e.target.name]: e.target.value })
    };

    const handleFileUpload = (uploadedFile) => {
        let reader = new FileReader();

        reader.onload = function(event) {
            setFile(event.target.result);
        };

        reader.readAsText(uploadedFile);
    };

    const searchLeads = () => {
        setLeadType('search')
        setLoading(true)
        API.post('/lender/return/search',searchData).then((response) => {
            setLeads(response.data.data)
            setDisputeList([])
            setLoading(false)
        });
    }

    const uploadLeads = () => {
        setLeadType('upload')
        setLoading(true)
        API.post('/lender/return/upload',{csv:file}).then((response) => {
            setLeads(response.data.data)
            setDisputeList([])
            setLoading(false)
        });
    }

    const disputeLead = (postingId) => {
        setDisputeList(
            [
                ...disputeList,
                { id: postingId, reason: null }
            ]
        )
    }

    const cancelDisputeLead = (postingId) => {
        setDisputeList(
            disputeList.filter(a => a.id !== postingId)
        );
    }

    const setReturnStatus = (postingId,reason) => {
        const tempData = disputeList.map(disputeListItem => {
            if (disputeListItem.id === postingId) {
                return {
                    ...disputeListItem,
                    reason: reason
                };

            } else {
                return disputeListItem;
            }
        });

        setDisputeList(tempData);
    }

    const submit = () => {
        let blankDesputeError = false;
        for (let i = 0; i < disputeList.length; i++) {
            if (disputeList[i].reason === null) {
                blankDesputeError = true;
            }
        }

        if (!blankDesputeError) {
            setReturnInProcess(true)
            API.post('/lender/return/submit', {disputeList}).then((response) => {
                setReturnResult(response.data.data)
                setReturnInProcess(false)
            });
        }else {
            toastr.error("Error","Please select a reason for all disputes")
        }
    }

    return <Container fluid className="p-0">
        <Button color="secondary" onClick={()=>window.location.reload()} className="float-right mt-2">
            <FontAwesomeIcon icon={faHistory}/> Log
        </Button>
        {returnResult ?
            <>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <a className="text-primary" onClick={()=>{
                            setActiveTab("search")
                            setSearchData({date:defaultDate})
                            setFile(null)
                            setLeads(null)
                            setLoading(false)
                            setLeadType(null)
                            setDisputeList([])
                            setReturnInProcess(false)
                            setReturnResult(null)
                        }}>Returns</a>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Return result</BreadcrumbItem>
                </Breadcrumb>
                <Card className="mt-2">
                    <div className="table-responsive" style={{maxHeight:"500px"}}>
                        <Table className="pb-4">
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Date</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Return status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {returnResult.map((lead, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{lead.data?.lead?lead.data.lead.id:lead.id}</td>
                                        {lead.data?.lead ?
                                            <>
                                                <td><Datetime dateTime={lead.data.lead.dateadd}/></td>
                                                <td>{lead.data.leaddata.email}</td>
                                                <td>{lead.data.leaddata.phone}</td>
                                                <td>{lead.status?<span className="text-success"><FontAwesomeIcon icon={faCheck}/> Requested</span>:<span className="text-danger"><FontAwesomeIcon icon={faTimes}/> Decline<br/>{lead.note}</span>}</td>
                                            </>
                                            :
                                            <>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="text-center">Lead not found</td>
                                            </>
                                        }
                                    </tr>
                                )
                            })
                            }
                            </tbody>
                        </Table>
                    </div>
                </Card>
            </>
            :
            <>
                <h1 className="h3 mb-3">Submit returns</h1>
                {returnInProcess ?
                    <div className="row justify-content-center mt-7">
                        <div className="col-auto text-center">
                            <Spinner color="primary"/>
                            <br/>
                            <div className="font-weight-bold text-center">Return in process, please wait</div>
                        </div>
                    </div>
                    :
                    <>
                        <div className={"tab default"}>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "search" })}
                                        onClick={() => {
                                            setActiveTab("search");
                                        }}
                                    >
                                        <strong>Search</strong>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "upload" })}
                                        onClick={() => {
                                            setActiveTab("upload");
                                        }}
                                    >
                                        <strong>Upload</strong>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="search">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12">
                                            <label>Date range</label>
                                            <DateRange
                                                onChange={changeData}
                                                name={'date'}
                                                value={defaultDate}
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <label>Email</label>
                                            <input type="text" className="form-control" name="email" onChange={handleChange}></input>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <label>Phone</label>
                                            <input type="text" className="form-control" name="phone" onChange={handleChange}></input>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12 col-sm-12">
                                            <label>Lead Id's (comma separated)</label>
                                            <textarea className="form-control" rows="4" name="leadid" onChange={handleChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="row mt-4 mb-1 justify-content-center">
                                        <div className="col-auto">
                                            <button className="btn btn-success" onClick={()=>{searchLeads()}}>Search</button>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="upload">
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <FileUploader handleChange={handleFileUpload} name="file" types={["CSV"]} label={"Upload or drop a CSV file right here"}/>
                                            <div className="mt-2 mb-2 text-center"><FontAwesomeIcon icon={faExclamation}/> Example format <br/><img src={csvExample} style={{width:"300px"}}/></div>
                                        </div>
                                    </div>
                                    <div className="row mt-2 justify-content-center">
                                        <div className="col-auto">
                                            <button className="btn btn-success" onClick={()=>{uploadLeads()}} disabled={!file}>Upload</button>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                        {(reasons && leadType === activeTab) &&
                        loading ?
                            <div className="row justify-content-center">
                                <div className="col-auto text-center">
                                    <Spinner color="primary"/>
                                </div>
                            </div>
                            :
                            leads?.length > 0 ?
                                <Card className="mt-2" style={{overflowX:"auto"}}>
                                    <div>
                                        <Table className="pb-4">
                                            <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Date</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th className="text-center" style={{width:"300px"}}>Dispute</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {leads.map((lead, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{lead.data?.lead?lead.data.lead.id:lead.id}</td>
                                                        {lead.data?.lead ?
                                                            <>
                                                                <td><Datetime dateTime={lead.data.lead.dateadd}/></td>
                                                                <td>{lead.data.leaddata.email}</td>
                                                                <td>{lead.data.leaddata.phone}</td>
                                                                <td className="text-center">{lead.allowed ?
                                                                    <>
                                                                        {disputeList.some(disputeListItem => disputeListItem.id === lead.data.lenderposting.id) ?
                                                                            <div style={{minWidth:"300px"}}>
                                                                                <ReturnReason name={lead.data.lenderposting.id} onChange={setReturnStatus} value={lead.reason} reasons={(lead.data.product.id in reasons)?reasons[lead.data.product.id]:[]}/>
                                                                                <div className="text-right mt-1">
                                                                                    <a className="text-primary" onClick={()=>cancelDisputeLead(lead.data.lenderposting.id)}>unselect</a>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div style={{minWidth:"300px"}}><button className="btn btn-primary" onClick={()=>disputeLead(lead.data.lenderposting.id)}>Dispute</button></div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        Not allowed by policy
                                                                    </>
                                                                }</td>
                                                            </>
                                                            :
                                                            <>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className="text-center">Lead not found</td>
                                                            </>
                                                        }
                                                    </tr>
                                                )
                                            })
                                            }
                                            </tbody>
                                        </Table>
                                    </div>
                                    {(disputeList && disputeList.length > 0) &&
                                        <div className="text-center mb-3 mt-2"><a className="btn btn-primary" onClick={()=>submit()}>Submit</a></div>
                                    }
                                </Card>
                                :
                                (!loading && leads !== null) &&
                                <CardBody className="text-center"><h5>Data not found</h5></CardBody>
                        }
                    </>
                }
            </>
        }
    </Container>
}

export default SubmitReturns