import React,{useState,useEffect} from "react";
import API from "../../utils/API";
import Select from "react-select";

const OrderStatusSelect = ({name,value,onChange}) => {
    const [status,setStatus] = useState(null)

    const changeSelect = (value) => {
        let i,values = []
        for (i=0;i<value?.length;i++) {
            values.push(value[i].value)
        }
        if (name) {
            onChange(name,values)
        }
    }

    useEffect(() => {
        if (value) {
            changeSelect(value)
        }
        API.get('/lender/system/orderstatus').then((response) => {
            let options,i,optionsList = []
            if (response.data.data) {
                options = response.data.data
                for (i=0;i<options.length;i++) {
                    optionsList.push({value:options[i].id,label:options[i].title})
                }
            }
            setStatus(optionsList)
        });
    }, [])

    return (
        <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={status}
            defaultValue={value}
            onChange={(value)=>changeSelect(value)}
            isMulti
            placeholder="Status"
        />
    )

}

export default OrderStatusSelect;
